<template>
  <NoteSection>
    <v-tabs
      v-model="activeTab"
      vertical
      color="success"
      class="col-sm-12 mb-5"
    >
      <v-tab :class="prognosisInvalid ? 'required-field required-tab' : ''">
        <strong v-if="prognosisInvalid">*</strong>
        Prognosis
      </v-tab>
      <v-tab :class="followupInvalid ? 'required-field required-tab' : ''">
        <strong v-if="followupInvalid">*</strong>
        Follow-Up
      </v-tab>
      <v-tab
        :class="goalsInvalid ? 'required-field required-tab' : ''"
      >
        <strong v-if="goalsInvalid">*</strong>
        Goals
      </v-tab>
      <v-tab
        :class="chronicWoundInvalid ? 'required-field required-tab' : ''"
      >
        <strong v-if="chronicWoundInvalid">*</strong>
        Chronic Wound
      </v-tab>
      <v-tab :class="offloadingInvalid ? 'required-field required-tab' : ''">
        <strong v-if="offloadingInvalid">*</strong>
        Offloading
      </v-tab>
      <v-tab :class="mattressInvalid ? 'required-field required-tab' : ''">
        <strong v-if="mattressInvalid">*</strong>
        Mattress
      </v-tab>
      <v-tab :class="antibioticsInvalid ? 'required-field required-tab' : ''">
        <strong v-if="antibioticsInvalid">*</strong>
        Antibiotics
      </v-tab>

      <!-- Prognosis -->
      <v-tab-item eager>
        <select-box
          v-model="section.prognosis"
          :items="prognoses"
          label="Overall Prognosis"
          class="col-sm-12"
        ></select-box>
        <text-area
          v-if="section.prognosis === 'Other'"
          v-model="section.prognosis_other"
          label="Enter other prognosis..."
          class="col-sm-12"
          required
          counter="6200"
          maxlength="6200"
        ></text-area>
        <checkbox
          v-model="section.excellent_care"
          label="Note facility staff provided excellent care?"
          class="col-sm-12"
        ></checkbox>
      </v-tab-item>

      <!-- Follow-Up -->
      <v-tab-item eager>
        <select-box
          v-model="section.follow_up_interval"
          :items="followUpIntervals"
          label="Choose Interval"
          class="col-sm-12"
        ></select-box>
        <text-area
          v-if="section.follow_up_interval === 'Twice per week'"
          v-model="section.follow_up_interval_reason"
          label="Enter reason for frequent follow-ups..."
          class="col-sm-12"
          required
        ></text-area>
        <text-area
          v-if="section.follow_up_interval === 'Other'"
          v-model="section.follow_up_interval_other"
          label="Enter other follow-up interval..."
          class="col-sm-12"
          required
          counter="2200"
          maxlength="2200"
        ></text-area>
        <check-boxes
          v-model="section.follow_up_activities"
          :items="followUpActivities"
          class="col-sm-12"
          column
          return-object
          hide-details
        ></check-boxes>
        <text-area
          v-if="section.follow_up_activities.some(x => x.title === 'Other')"
          v-model="section.follow_up_activity_other"
          label="Enter other activity..."
          class="col-sm-12"
          required
          counter="2700"
          maxlength="2700"
        ></text-area>
      </v-tab-item>

      <!-- Goals -->
      <v-tab-item eager>
        <alert
          v-if="!hasWoundTreatmentType"
          class="col-sm-12 mb-0 py-1"
        >
          No wound treatments found.  This section is not applicable.
        </alert>
        <check-boxes
          v-model="section.goals"
          :items="goals"
          class="col-sm-12"
          column
          return-object
          hide-details
          :disabled="!hasWoundTreatmentType"
          :required="hasWoundTreatmentType"
        ></check-boxes>
        <text-area
          v-if="section.goals.some(x => x.title === 'Other')"
          v-model="section.goals_other"
          label="Enter other goals..."
          class="col-sm-12"
          required
          counter="1000"
          maxlength="1000"
        ></text-area>
      </v-tab-item>

      <!-- Chronic Wound -->
      <v-tab-item eager>
        <alert
          v-if="!hasWoundTreatmentType"
          class="col-sm-12 mb-0 py-1"
        >
          No wound treatments found.  This section is not applicable.
        </alert>
        <check-boxes
          v-model="section.chronic_wounds"
          :items="chronicWounds"
          class="col-sm-12"
          columns="2"
          return-object
          hide-details
          :disabled="!hasWoundTreatmentType"
        ></check-boxes>
      </v-tab-item>

      <!-- Offloading -->
      <v-tab-item eager>
        <select-box
          v-model="section.offloading"
          :items="offloadings"
          label="Offloading"
          class="col-sm-12"
        ></select-box>
        <text-area
          v-if="section.offloading === 'Other'"
          v-model="section.offloading_other"
          label="Enter other offloading information..."
          class="col-sm-12"
          required
          counter="1300"
          maxlength="1300"
        ></text-area>
      </v-tab-item>

      <!-- Mattress -->
      <v-tab-item eager>
        <radio-buttons
          v-model="section.mattress"
          :items="mattresses"
          class="col-sm-12"
          clearable
          hide-details
        ></radio-buttons>
        <!-- Removed per SWC-783
        <checkbox
          v-model="section.low_loss_mattress"
          label="Patient has low air loss mattress?"
          class="col-sm-12"
          hide-details
          row
        ></checkbox>
        -->
        <label v-if="section.mattress">Mattress Type</label>
        <check-boxes
          v-if="section.mattress"
          v-model="section.mattress_types"
          :items="mattressTypes"
          class="col-sm-12"
          columns="2"
          column-sort
          return-object
          hide-details
          radio-buttons
          clearable
          required
        ></check-boxes>
        <text-area
          v-if="section.mattress && (section.mattress_types.some(x => x.title === 'Other'))"
          v-model="section.mattress_types_other"
          label="Enter other mattress type..."
          class="col-sm-12"
          required
          counter="700"
          maxlength="700"
        ></text-area>
      </v-tab-item>

      <!-- Antibiotics -->
      <v-tab-item eager>
        <checkbox
          v-model="section.is_on_antibiotics"
          label="Patient on antibiotics?"
          class="col-sm-12"
        ></checkbox>
        <text-field
          v-if="section.is_on_antibiotics"
          v-model="section.antibiotic_name"
          label="Antibiotic Name"
          class="col-sm-12"
          required
        ></text-field>
        <text-field
          v-if="section.is_on_antibiotics"
          v-model="section.antibiotic_dose"
          label="Dose"
          class="col-sm-6"
          required
        ></text-field>
        <select-box
          v-if="section.is_on_antibiotics"
          v-model="section.antibiotic_route"
          :items="antibioticRoutes"
          label="Route"
          class="col-sm-6"
          required
        >
        </select-box>
        <date-picker
          v-if="section.is_on_antibiotics"
          v-model="section.antibiotic_date_started"
          :max="$date().format('YYYY-MM-DD')"
          label="Date Started"
          class="col-sm-6"
          clearable
          required
        ></date-picker>
        <text-field
          v-if="section.is_on_antibiotics"
          v-model="section.antibiotic_duration"
          class="col-sm-6"
          label="Duration (Days)"
          numeric
          mask="###"
          required
        ></text-field>
      </v-tab-item>
    </v-tabs>

    <alert
      v-if="apItemsChanged"
      class="mx-2"
    >
      Update the assessment plan using the blue button below to reflect the changes made in the A&P section above.
    </alert>

    <text-area
      v-model="section.assessment_plan"
      :label="!isGTubeOnly ? 'Enter assessment plan...' : 'No text to generate; for g-tube-only notes, enter it manually.'"
      class="col-sm-12"
      ai
      :prepend-button-disabled="isGTubeOnly"
      counter="18800"
      maxlength="18800"
      word-count="8"
      required
      @click:prepend="generateAssessmentPlan"
    ></text-area>
  </NoteSection>
</template>

<script>
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
import Vue from 'vue'
import { mapGetters } from 'vuex'

const initialData = {
  prognosis: null,
  prognosis_other: null,
  excellent_care: null,
  follow_up_interval: null,
  follow_up_interval_other: null,
  follow_up_interval_reason: null,
  follow_up_activities: [],
  follow_up_activity_other: null,
  goals: [],
  goals_other: null,
  chronic_wounds: [],
  offloading: null,
  offloading_other: null,
  low_loss_mattress: null,
  mattress: null,
  mattress_types: [],
  mattress_types_other: null,
  is_on_antibiotics: null,
  antibiotic_name: null,
  antibiotic_dose: null,
  antibiotic_route: null,
  antibiotic_date_started: null,
  antibiotic_duration: null,
  assessment_plan: null,
}

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    hasWoundTreatmentType: {
      type: Boolean,
      default: false,
    },
    highlightForm: {
      type: Boolean,
      default: null,
    },
    woundList: {
      type: Array,
      default: null,
    },
    treatmentStatus: {
      type: Boolean,
      default: false,
    },
    patient: {
      type: Object,
      default: null,
    },
    encounter: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      activeTab: 0,
      section: {
        ...this.$lodash.cloneDeep(initialData),
        ...this.value,
      },
      honorific: `${this.patient.gender === 'Male' ? 'Mr.' : 'Ms'} ${this.patient.last_name}`,
      apItemsChanged: false,
    }
  },
  computed: {
    ...mapGetters('encounters', [
      'antibioticRoutes',
      'chronicWounds',
      'followUpActivities',
      'followUpIntervals',
      'goals',
      'mattresses',
      'mattressTypes',
      'offloadings',
      'prognoses',
    ]),
    prognosisInvalid() {
      return this.section.prognosis === 'Other' && !this.section.prognosis_other
    },
    followupInvalid() {
      return (this.section.follow_up_interval === 'Twice per week' && !this.section.follow_up_interval_reason)
        || (this.section.follow_up_interval === 'Other' && !this.section.follow_up_interval_other)
        || (this.section.follow_up_activities.some(x => x.title === 'Other') && !this.section.follow_up_activity_other)
    },
    goalsInvalid() {
      return (this.hasWoundTreatmentType && this.section.goals.length === 0)
        || (this.section.goals.some(x => x.title === 'Other') && !this.section.goals_other)
    },
    chronicWoundInvalid() {
      // Removed per SWC-782
      // return this.hasWoundTreatmentType && this.section.chronic_wounds.length === 0
      return false
    },
    offloadingInvalid() {
      return this.section.offloading === 'Other' && !this.section.offloading_other
    },
    mattressInvalid() {
      return this.section.low_loss_mattress && this.section.mattress_types.some(x => x.title === 'Other') && !this.section.mattress_types_other
    },
    antibioticsInvalid() {
      return !!this.section.is_on_antibiotics
        && (!this.section.antibiotic_name
          || !this.section.antibiotic_dose
          || !this.section.antibiotic_route
          || !this.section.antibiotic_date_started
          || !this.section.antibiotic_duration
        )
    },
    woundTreatments() {
      return this.woundList.filter(x => x.practice_type_id === 1)
    },
    dermTreatments() {
      return this.woundList.filter(x => x.practice_type_id === 6)
    },
    gTubeTreatments() {
      return this.woundList.filter(x => x.practice_type_id === 5)
    },
    isGTubeOnly() {
      if (this.woundTreatments.length > 0 || this.dermTreatments.length > 0) return false

      if (this.gTubeTreatments.length > 0) return true

      return false
    },
    woundLocations() {
      return this.$custom.toUniqueArray(this.woundTreatments.map(x => x.location && x.location.location_text))
    },
    dermLocations() {
      return this.$custom.toUniqueArray(this.dermTreatments.map(x => x.location && x.location.location_text))
    },
    debridementProcedures() {
      return this.$custom.removeNoneOther(
        [].concat(
          ...this.woundTreatments
            .filter(x => this.$store.getters['baseData/procedureFromId'](x.treatment.procedure_id).toLowerCase().includes('debrid'))
            .map(y => this.$store.getters['baseData/procedureFromId'](y.treatment.procedure_id).replaceAll('.', '')),
        ),
      )
    },
    nonDebridementProcedures() {
      return this.$custom.removeNoneOther(
        [].concat(
          ...this.woundTreatments
            .filter(x => !this.$store.getters['baseData/procedureFromId'](x.treatment.procedure_id).toLowerCase().includes('debrid'))
            .map(y => this.$store.getters['baseData/procedureFromId'](y.treatment.procedure_id).replaceAll('.', '')),
        ),
      )
    },
    nonDebridementWounds() {
      return this.$custom.removeNoneOther(
        [].concat(
          ...this.woundTreatments
            .filter(x => !this.$store.getters['baseData/procedureFromId'](x.treatment.procedure_id).toLowerCase().includes('debrid'))
            .map(y => y.location && y.location.location_text),
        ),
      )
    },
    etiologies() {
      return this.$custom.removeNoneOther(
        [].concat(
          ...this.woundTreatments.map(x => x.treatment.etiology),
          ...this.woundTreatments.map(x => x.treatment.etiology_other),
        ),
      )
    },
    isPressureEtiology() {
      return this.etiologies.findIndex(x => this.$custom.toLowerCase(x).includes('pressure')) !== -1
    },
    reasonsForOngoingDebridement() {
      return this.$custom.removeNoneOther(
        [].concat(
          ...this.woundTreatments.map(x => {
            const reason = x.treatment.wound_treatment.ongoing_debridement_reason

            return reason && !reason.isArray ? reason.split(',') : []
          }),
          ...this.woundTreatments.map(x => x.treatment.wound_treatment.ongoing_debridement_reason_other),
        ),
      )
    },
    debridementWoundLocations() {
      return this.$custom.toUniqueArray(
        [].concat(
          ...this.woundTreatments
            .filter(x => this.$store.getters['baseData/procedureFromId'](x.treatment.procedure_id).toLowerCase().includes('debrid'))
            .map(y => y.location && y.location.location_text),
        ),
      )
    },
    isSeventyPercentage() {
      return (Math.floor(Math.random() * 10) + 1) < 8
    },
    apSection() {
      return { ...this.section }
    },
  },
  watch: {
    apSection: {
      deep: true,
      handler(curVal, oldVal) {
        this.$emit('input', this.section)

        // If prognosis type was changed from "other", clear "other" text.
        if (this.section.prognosis !== 'Other') {
          // Clear the prognosis type other value
          this.section.prognosis_other = null
        }

        // If follow up interval was changed from "other", clear "other" text.
        if (this.section.follow_up_interval !== 'Other') {
          // Clear the follow up interval type other value
          this.section.follow_up_interval_other = null
        }

        // If follow up interval was changed from "other", clear "other" text.
        if (this.section.follow_up_interval !== 'Twice per week') {
          // Clear the follow up interval type other value
          this.section.follow_up_interval_reason = null
        }

        // If follow up activities was changed from "other", clear "other" text.
        if (!this.section.follow_up_activities.some(selectedVal => selectedVal.title === 'Other')) {
          // Clear the follow up activities type other value
          this.section.follow_up_activity_other = null
        }

        // If goals type was changed from "other", clear "other" text.
        if (!this.section.goals.some(selectedVal => selectedVal.title === 'Other')) {
          // Clear the goals other value
          this.section.goals_other = null
        }

        // If offloading type was changed from "other", clear "other" text.
        if (this.section.offloading !== 'Other') {
          // Clear the offloading type other value
          this.section.offloading_other = null
        }

        // If mattress type was changed from "other", clear "other" text.
        if (!this.section.mattress_types.some(selectedVal => selectedVal.title === 'Other')) {
          // Clear the mattress type other value
          this.section.mattress_types_other = null
        }

        // If antibiotics type was changed to false clear the fields.
        if (!this.section.is_on_antibiotics) {
          // Clear the antibiotics types values
          this.section.antibiotic_date_started = null
          this.section.antibiotic_dose = null
          this.section.antibiotic_duration = null
          this.section.antibiotic_name = null
          this.section.antibiotic_route = null
        }

        // notify user to update assessment plan for changes in A&P section
        if (curVal.assessment_plan !== oldVal.assessment_plan) {
          this.apItemsChanged = false
        } else if (curVal.prognosis === 'Other' && (oldVal.prognosis !== curVal.prognosis)) {
          if (curVal.prognosis_other !== oldVal.prognosis_other) {
            this.apItemsChanged = true
          }
        } else if (curVal.follow_up_interval === 'Other' && (oldVal.follow_up_interval !== curVal.follow_up_interval)) {
          if (curVal.follow_up_interval_other !== oldVal.follow_up_interval_other) {
            this.apItemsChanged = true
          }
        } else if (curVal.follow_up_interval === 'Twice per week' && (oldVal.follow_up_interval !== curVal.follow_up_interval)) {
          if (curVal.follow_up_interval_reason !== oldVal.follow_up_interval_reason) {
            this.apItemsChanged = true
          }
        } else if (curVal.follow_up_activities.some(selectedVal => selectedVal.title === 'Other') && !oldVal.follow_up_activities.some(selectedVal => selectedVal.title === 'Other')) {
          if (curVal.follow_up_activity_other !== oldVal.follow_up_activity_other) {
            this.apItemsChanged = true
          }
        } else if (curVal.goals.some(selectedVal => selectedVal.title === 'Other') && !oldVal.goals.some(selectedVal => selectedVal.title === 'Other')) {
          if (curVal.goals_other !== oldVal.goals_other) {
            this.apItemsChanged = true
          }
        } else if (curVal.offloading === 'Other' && (oldVal.offloading !== curVal.offloading)) {
          if (curVal.offloading_other !== oldVal.offloading_other) {
            this.apItemsChanged = true
          }
        } else if (curVal.is_on_antibiotics) {
          if (curVal.antibiotic_date_started !== null && curVal.antibiotic_dose !== null && curVal.antibiotic_duration !== null && curVal.antibiotic_name !== null && curVal.antibiotic_route !== null) {
            this.apItemsChanged = true
          }
        } else {
          this.apItemsChanged = true
        }
      },
    },
    highlightForm() {
      // Select the first tab with invalid fields
      if (this.highlightForm) {
        if (this.prognosisInvalid) this.activeTab = 0
        else if (this.followupInvalid) this.activeTab = 1
        else if (this.goalsInvalid) this.activeTab = 2
        else if (this.chronicWoundInvalid) this.activeTab = 3
        else if (this.offloadingInvalid) this.activeTab = 4
        else if (this.mattressInvalid) this.activeTab = 5
        else if (this.antibioticsInvalid) this.activeTab = 6
      }
    },
    hasWoundTreatmentType() {
      // Check if user deleted the last wound treatment where goals and chronic wounds were already filled in.
      if (!this.hasWoundTreatmentType
        && (this.section.goals.length > 0
          || this.section.goals_other
          || this.section.chronic_wounds.length > 0)
      ) {
        // Notify user to review A&P if it's already filled in prior to deleting the last wound treatment.
        if (this.section.assessment_plan) Vue.store.dispatch('notify', { value: 'A&P section review needed: "Goals" and "Chronic Wound" cleared.', color: 'warning' })

        // Clear goals and chronic wounds values.
        this.section.goals = []
        this.section.goals_other = null
        this.section.chronic_wounds = []
      }
    },
  },
  mounted() {
    // eslint-disable-next-line no-return-assign
    this.$root.$on('recentlyHospitalizedAP', () => this.apItemsChanged = true)
  },
  beforeDestroy() {
    // Remove the 'recentlyHospitalizedAP' event listener to prevent memory leaks
    this.$root.$off('recentlyHospitalizedAP')
  },
  methods: {
    formatDate(date) {
      return this.$date(date).format('MM/DD/YYYY')
    },

    // Toast notification for other text fields when left empty
    emptyOtherFieldNotify() {
      this.$store.dispatch('notify', { value: 'Leaving required fields empty will result in validation errors', color: 'warning' })
    },
    generateAssessmentPlan() {
      if (!this.treatmentStatus) {
        this.$root.confirm({
          titleIconColor: 'error',
          title: 'Treatments not validated!',
          body: 'You must have at least one treatment for this encounter and all encounter treatments must be validated before generating automatic text.',
          cancel: false,
        })

        return
      }

      // Checks each field that contains 'other' text fields and notifies
      if (this.section.prognosis === 'Other') {
        if (this.section.prognosis_other === '' || this.section.prognosis_other === null) this.emptyOtherFieldNotify()
      }

      if (this.section.follow_up_interval === 'Other') {
        if (this.section.follow_up_interval_other === '' || this.section.follow_up_interval_other === null) this.emptyOtherFieldNotify()
      }

      if (this.section.follow_up_interval === 'Twice per week') {
        if (this.section.follow_up_interval_reason === '' || this.section.follow_up_interval_reason === null) this.emptyOtherFieldNotify()
      }

      if (this.section.follow_up_activities.some(x => x.title === 'Other')) {
        if (this.section.follow_up_activity_other === '' || this.section.follow_up_activity_other === null) this.emptyOtherFieldNotify()
      }

      if (this.section.offloading === 'Other') {
        if (this.section.offloading_other === '' || this.section.offloading_other === null) this.emptyOtherFieldNotify()
      }

      if (this.section.goals.some(x => x.title === 'Other')) {
        if (this.section.goals_other === '' || this.section.goals_other === null) this.emptyOtherFieldNotify()
      }

      if ((this.section.mattress_types.some(x => x.title === 'Other'))) {
        if (this.section.mattress_types_other === '' || this.section.mattress_types_other === null) this.emptyOtherFieldNotify()
      }

      // part 1
      let assessmentPlan = ''
      if ((this.woundTreatments.length + this.dermTreatments.length) > 0) {
        assessmentPlan += this.$custom.randomString(['The', 'This'])
        assessmentPlan += 'patient has '
        if (this.woundTreatments.length === 0) {
          assessmentPlan += `${this.dermTreatments.length === 1 ? 'a lesion' : 'lesions'}`
        } else {
          assessmentPlan += `${this.woundTreatments.length === 1 ? 'a wound' : 'wounds'}`
        }
        assessmentPlan += this.$custom.randomString(['located', 'found'])
        assessmentPlan += this.$custom.randomString(['at the', 'on the'])
        if (this.woundTreatments.length === 0) {
          const dermTreatmentsText = this.$custom.toListCommaAnd(this.dermLocations, null, 'none')
          assessmentPlan += this.$custom.unCapitalizeFirstLetter(dermTreatmentsText)
        } else {
          const woundTreatmentsText = this.$custom.toListCommaAnd(this.woundLocations, null, 'none')
          assessmentPlan += this.$custom.unCapitalizeFirstLetter(woundTreatmentsText)
        }
        assessmentPlan += '.  '

        if (!this.$custom.isEmpty(this.debridementProcedures)) {
          assessmentPlan += `The wound${
            this.debridementProcedures.length > 1 ? 's' : ''
          } debrided today ${
            this.debridementProcedures.length > 1 ? 'include:' : 'was at the'} ${
            this.$custom.unCapitalizeFirstLetter(this.$custom.toListCommaAnd(this.debridementWoundLocations, null, 'none'))
          }.  ${
            this.debridementProcedures.length > 1 ? 'Of these:' : 'For this'} wound${
            this.debridementProcedures.length > 1 ? 's' : ''}, there was ${
            this.$custom.randomString(
              ['evidence', 'an indication', 'a sign', 'confirmation'],
            )} of tissue ${this.$custom.randomString(
            ['breakdown', 'deterioration', 'degradation', 'decline'],
          )} ${this.$custom.randomString(
            ['requiring',
              'which will require',
              'necessitating',
              'which will necessitate',
              'which will need',
              'entailing',
              'which will entail'],
          )} ${this.$custom.randomString(
            ['continued', 'continuing', 'ongoing'],
          )} ${this.$custom.randomString(
            ['management', 'administration', 'care', 'supervision', 'surveillance'],
          )} and ${this.$custom.randomString(
            ['may', 'may very well', 'will likely', 'will probably'],
          )} ${this.$custom.randomString(
            ['require', 'need'],
          )} future debridement.   ${this.$custom.randomString(
            ['No guarantee for wound healing can be made',
              'Healing of these wounds can not be guaranteed'],
          )} ${this.$custom.randomString(
            ['given the',
              'due to the',
              'as a result of the',
              'because of the',
              'considering the',
              'in view of the',
              'owing to the'],
          )} ${this.$custom.randomString(
            ['patient', 'patient\'s'],
          )} ${this.$custom.randomString(
            ['risk factors and diagnoses',
              'diagnoses and risk factors',
              'risk factors/diagnoses',
              'diagnoses/risk factors'],
          )} ${this.$custom.randomString(
            ['that', 'which'],
          )} ${this.$custom.randomString(
            ['impact', 'affect', 'influence', 'have an effect on', 'alter'],
          )} the ${this.$custom.randomString(
            ['condition', 'state', 'healing', 'healing progress', 'progress'],
          )} of ${
            this.debridementProcedures.length > 1 ? 'these wounds' : 'this wound'}.  `
        }
        if (this.isPressureEtiology) {
          if (this.encounter.medical_histories.length > 0 || this.encounter.medical_histories_other) {
            assessmentPlan += `${this.$custom.randomString(
              ['The', 'This'],
            )} patient has diagnoses ${this.$custom.randomString(
              ['which include but are not limited to',
                'including but not limited to',
                'including (but not limited to)'],
            ).trim()}: ${this.$custom.toListComma(this.encounter.medical_histories, this.encounter.medical_histories_other, 'lc')}, etc.,  ${this.$custom.randomString(
              ['which',
                'that'],
            )} ${this.$custom.randomString(
              ['may',
                'could',
                'might',
                'could possibly',
                'could potentially',
                'have the capacity to',
                'have the potential to'],
            )} ${this.$custom.randomString(
              ['lead to',
                'contribute to',
                'cause',
                'produce',
                'generate'],
            )} ${this.$custom.randomString(
              ['further',
                'additional',
                'added'],
            )} ${this.$custom.randomString(
              ['deterioration',
                'degradation',
                'decline'],
            )} or wound chronicity, ${this.$custom.randomString(
              ['hindering',
                'retarding',
                'slowing',
                'slowing down',
                'delaying',
                'impeding',
                'limiting',
                'hampering',
                'handicapping',
                'inhibiting',
                'interfering with',
                'precluding',
                'arresting',
                'contravening'],
            )} wound healing.  `
          }
          if (this.encounter.risk_factors.length > 0 || this.encounter.risk_factors_other) {
            assessmentPlan += `The patient also has ${
              this.encounter.risk_factors.length > 1
                ? 'the following risk factors:'
                : 'the risk factor of'
            } ${this.$custom.toListCommaAnd(this.encounter.risk_factors, this.encounter.risk_factors_other, 'lc')} ${this.$custom.randomString(
              ['that',
                'which'],
            )} ${this.$custom.randomString(
              ['can',
                'may',
                'could',
                'might'],
            )} ${this.$custom.randomString(
              ['contribute to',
                'exacerbate'],
            )} the worsening of the pressure injury.  `
          }
        } else if (this.encounter.risk_factors.length > 0) {
          assessmentPlan += `The patient is also at risk for ${this.$custom.randomString(
            ['the development of',
              'developing'],
          )} a pressure injury ${this.$custom.randomString(
            ['given',
              'due to',
              'as a result of',
              'because of'],
          )} ${
            this.encounter.risk_factors.length > 1
              ? 'the following risk factor:'
              : 'the risk factor of'} ${this.$custom.toListCommaAnd(this.encounter.risk_factors, null, 'lc')}.  `
        }
        if (this.reasonsForOngoingDebridement.length > 0) {
          assessmentPlan += `\n\nReason for continued debridement: ${this.honorific} ${this.$custom.randomString(
            ['continues to require',
              'continues to need',
              'will need',
              'necessitates',
              'requires'],
          )} ${this.$custom.randomString(
            ['', 'ongoing'],
          )} ${this.$custom.randomString(
            ['weekly debridement',
              'debridement every week',
              'debridement on a weekly basis'],
          )} ${this.$custom.randomString(
            ['as',
              'due to how',
              'because',
              'since',
              'seeing as how',
              'as a result of how',
              'considering',
              'considering that'],
          )} ${this.patient.gender === 'Male' ? 'he' : 'she'} ${this.$custom.randomString(
            ['exhibits',
              'is exhibiting',
              'experiences',
              'is experiencing',
              'displays',
              'is displaying',
              'shows',
              'is showing',
              'presents',
              'is presenting',
              'demonstrates',
              'is demonstrating'],
          )}`

          if (this.reasonsForOngoingDebridement.length === 1) {
            assessmentPlan += `${this.$custom.toListCommaAnd(this.reasonsForOngoingDebridement, null, 'lc')}, `
            assessmentPlan += `${this.$custom.randomString(
              ['a factor',
                'a condition',
                'a symptom',
                'an indication',
                'a sign',
                'a signal',
                'a signifier'],
            )}`
          } else {
            assessmentPlan += `${this.$custom.randomString(
              ['factors',
                'conditions',
                'symptoms',
                'indications',
                'signs',
                'signals',
                'signifiers'],
            )}`
          }

          assessmentPlan += `${this.$custom.randomString(
            ['that', 'which'],
          )} ${this.$custom.randomString(
            ['can',
              'may'],
          )} ${this.$custom.randomString(
            ['hinder',
              'retard',
              'slow',
              'slow down',
              'delay',
              'impede',
              'limit',
              'hamper',
              'handicap',
              'inhibit',
              'interfere with',
              'preclude',
              'arrest',
              'contravene'],
          )} ${this.$custom.randomString(
            ['the', ''],
          )} ${this.$custom.randomString(
            ['wound healing',
              'healing of wounds',
              'wound improvement',
              'improvement of wounds'],
          )}`
          if (this.reasonsForOngoingDebridement.length > 1) {
            assessmentPlan += `${this.$custom.randomString(
              ['which are',
                'which include',
                'including',
                'which consist of',
                'consisting of',
                'comprising of',
                'comprised of'],
            )}: ${this.$custom.toListCommaAnd(this.reasonsForOngoingDebridement, null, 'lc')}.  `
          } else {
            assessmentPlan += '.'
          }
        }
        if (this.nonDebridementWounds.length > 0) {
          assessmentPlan += `\n\nThe ${this.nonDebridementWounds.length > 1 ? 'following' : ''} ${
            this.nonDebridementWounds.length > 1 ? 'wounds are' : 'wound is'
          } stable and require${
            this.nonDebridementWounds.length > 1 ? '' : 's'
          } continued topical wound dressing therapy as noted above${
            this.nonDebridementWounds.length > 1
              ? `: ${this.$custom.toListCommaAnd(this.nonDebridementWounds, null, 'lc')}`
              : ''
          }.`
        }
        if (!this.$custom.isEmpty(this.section.chronic_wounds)) {
          assessmentPlan += `\n\n${this.$custom.randomString(
            ['The', 'This'],
          )} patient has a chronic wound which may or may not heal and may worsen ${this.$custom.randomString(
            ['as a result of', 'due to', 'because of'],
          )} ${
            this.$custom.toListCommaAnd(this.section.chronic_wounds, null, 'lc')
          }.  `
        }
        if (this.encounter.recently_hospitalized) {
          assessmentPlan += `\n\n${this.$custom.randomString(
            ['The patient has had a recent hospitalization',
              'The patient has been recently hospitalized',
              'Patient has had a recent hospitalization',
              'Patient has been recently hospitalized',
              'The patient has been at the hospital recently'],
          )}${
            this.encounter.recently_hospitalized_date
              ? `on ${this.$date(this.encounter.recently_hospitalized_date).format('MM/DD/YYYY')}`
              : ''
          }${this.encounter.recently_hospitalized_related ? this.$custom.randomString(
            ['due to a wound-related issue',
              'for a wound-related issue',
              'due to a wound issue',
              'for a wound issue'],
          ) : ''}.  ${this.$custom.randomString(
            ['Given that the patient was transported outside of the facility, to the hospital, and returned, wound healing may be negatively affected',
              'As the patient was transported to the hospital, wound healing might be negatively affected by contact with agents outside of the facility',
              'Increased risk of wound decline may occur due to travel outside of the facility',
              'The possibility exists that the patient\'s wound healing progress was negatively impacted as a result of the patient transport',
              'The movement of the patient outside of the facility may have negatively impacted the wound healing progress of this patient',
              'Transporting this patient to the hospital and back may have had a negative effect on this patient\'s wound healing progress',
              'Transportation of the patient to and from the hospital, along with the hospitalization, can negatively impact the status of the patient\'s wound. The patient may experience a higher instance of wound decline, poor healing and other potential negative outcomes from this experience'],
          )}.`
        }
        if (this.section.prognosis) {
          if (this.section.prognosis !== 'Other' || (this.section.prognosis === 'Other' && this.section.prognosis_other)) {
            assessmentPlan += '\n\nPrognosis: '
            if (this.section.prognosis === 'Unknown') {
              assessmentPlan += `${this.$custom.randomString(
                ['I was unable to make a determination about the prognosis of this patient',
                  'I was unable to establish a prognosis for this patient',
                  'A prognosis for this patient was not able to be made',
                  'A prognosis for this patient was not able to be established',
                  'No prognosis could be determined',
                  'No prognosis could be established',
                  'No prognosis could be determined for this patient',
                  'No prognosis could be established for this patient',
                  'No prognosis for this patient could be determined',
                  'No prognosis for this patient could be established'],
              )} ${this.isSeventyPercentage ? this.$custom.randomString(
                ['at this time.',
                  'during this visit.',
                  'today.'],
              ) : ''}`
            } else {
              assessmentPlan += `${this.$custom.randomString(
                ['I',
                  'We'],
              )} ${this.$custom.randomString(
                ['believe',
                  'expect',
                  'consider',
                  'feel'],
              )} the ${this.$custom.randomString(
                ['overall', ''],
              )} prognosis for ${this.$custom.randomString(
                ['the',
                  'this'],
              )} patient`
              if (this.$custom.isEmpty(this.debridementWoundLocations)) {
                assessmentPlan += ' is '
              } else {
                assessmentPlan += `'s ${
                  this.$custom.toListCommaAnd(this.debridementWoundLocations, null, 'lc')
                } to be`
              }
              if (this.section.prognosis === 'Other' && this.section.prognosis_other !== '') {
                assessmentPlan += ` ${this.$custom.toLowerCase(this.section.prognosis_other)}.  `
              } else {
                assessmentPlan += ` ${this.$custom.toLowerCase(this.section.prognosis)}.  `
              }
            }
          }
        }
        if (this.section.follow_up_interval) {
          if (this.section.follow_up_interval !== 'Other' || (this.section.follow_up_interval === 'Other' && this.section.follow_up_interval_other)) {
            assessmentPlan += '\n\nFollow-up: follow up at an interval of '
            if (this.section.follow_up_interval_other) {
              assessmentPlan += ` ${this.section.follow_up_interval_other}`
            } else {
              assessmentPlan += ` ${this.section.follow_up_interval}`
            }
            if (this.$custom.isEmpty(this.section.follow_up_activities) || (this.section.follow_up_activities.length === 1 && this.section.follow_up_activities[0].title === 'Other' && !this.section.follow_up_activity_other)) {
              assessmentPlan += '.  '
            } else {
              assessmentPlan += ', covering the following items:\n'
              assessmentPlan += `\t- ${this.$custom.removeNoneOther([...this.section.follow_up_activities, { title: this.section.follow_up_activity_other }]).join('\n\t- ')}`
            }
            if (this.$custom.toLowerCase(this.section.follow_up_interval) === 'twice per week' && this.section.follow_up_interval_reason) {
              assessmentPlan += `\n\nReason for visiting twice per week: ${this.section.follow_up_interval_reason}`
            }
          }
        } else if (!this.$custom.isEmpty(this.section.follow_up_activities) && !(this.section.follow_up_activities.length === 1 && this.section.follow_up_activities[0].title === 'Other' && !this.section.follow_up_activity_other)) {
          assessmentPlan += '\n\nFollow-up: followups shall cover the following items:\n'
          assessmentPlan += `\t- ${this.$custom.removeNoneOther([...this.section.follow_up_activities, { title: this.section.follow_up_activity_other }]).join('\n\t- ')}`
        }

        // variable to view if 'Other' is among selected options
        const isOtherValid = this.section.goals.some(option => option.title === 'Other')
        if ((isOtherValid && this.section.goals_other) || (this.section.goals.length > 0 && !isOtherValid)) {
          assessmentPlan += `\n\nGoals: the overall goal${
            this.section.goals.length > 1 ? 's ' : ''
          } for the`
          if ((this.woundTreatments.length + this.dermTreatments.length) > 1) {
            assessmentPlan += ' wounds '
          } else {
            assessmentPlan += ' wound '
          }
          if (this.section.goals.length > 1) {
            assessmentPlan += 'are '
          } else {
            assessmentPlan += 'is '
          }
          assessmentPlan += `${this.$custom.toListCommaAnd(this.section.goals, this.section.goals_other, 'lc')}.`
        }
        if (this.section.offloading) {
          if (this.section.offloading !== 'Other' || (this.section.offloading === 'Other' && this.section.offloading_other)) {
            assessmentPlan += `\n\nOffloading: continue offloading: ${this.$custom.removeNoneOther([this.section.offloading, this.section.offloading_other])}.`
          }
        }
        if (this.section.mattress && this.$custom.toListCommaAnd(this.section.mattress_types, this.section.mattress_types_other, 'lc').length !== 2) {
          if (this.$custom.toListCommaAnd(this.section.mattress_types, this.section.mattress_types_other, 'lc').length === 7) {
            if (this.section.mattress_types_other) {
              assessmentPlan += `  ${
                this.$custom.toLowerCase(this.section.mattress) === 'continue' ? 'Continue' : 'A'} ${
                this.$custom.toListCommaAnd(this.section.mattress_types, this.section.mattress_types_other, 'lc')
                  ?.replace('clinitron', 'Clinitron')} mattress ${
                this.$custom.toLowerCase(this.section.mattress) === 'continue' ? '.  ' : 'is recommended.  '}`
            }
          } else {
            assessmentPlan += `  ${
              this.$custom.toLowerCase(this.section.mattress) === 'continue' ? 'Continue' : 'A'} ${
              this.$custom.toListCommaAnd(this.section.mattress_types, this.section.mattress_types_other, 'lc')
                ?.replace('clinitron', 'Clinitron')} mattress ${
              this.$custom.toLowerCase(this.section.mattress) === 'continue' ? '.  ' : 'is recommended.  '}`
          }
        }

        // Do not generate text for antibiotics section unless all fields are filled
        if (this.section.is_on_antibiotics && this.section.antibiotic_date_started !== null && this.section.antibiotic_dose !== null && this.section.antibiotic_duration !== null && this.section.antibiotic_duration !== '' && this.section.antibiotic_name !== null && this.section.antibiotic_route !== null) {
          assessmentPlan += `\n\n${this.$custom.randomString(
            ['The',
              'This'],
          )} patient started undergoing antibiotic therapy on ${
            this.formatDate(this.section.antibiotic_date_started)} using ${
            this.section.antibiotic_dose} of ${
            this.section.antibiotic_name} administered via ${
            this.section.antibiotic_route} and continued for ${
            this.section.antibiotic_duration} days.`
        } else if (this.section.is_on_antibiotics) {
          this.emptyOtherFieldNotify()
        }
        if (this.section.excellent_care) {
          assessmentPlan += `\n\n${this.$custom.randomString(
            [!this.$custom.isEmpty(this.woundTreatments)
              ? 'Excellent work by facility staff to prevent and manage patient wounds.'
              : '',
            !this.$custom.isEmpty(this.debridementProcedures)
              ? 'Excellent post debridement care by facility nursing staff.'
              : '',
            'Excellent care by treatment nurses.',
            'Excellent patient care by facility staff.',
            'Facility staff providing excellent care.',
            'Outstanding care by facility staff.'].filter(x => x !== ''),
          )}`
        }
      }
      this.apItemsChanged = false
      this.section.assessment_plan = this.$custom.clean(assessmentPlan, 'Assessment Plan')
    },
  },
}
</script>
